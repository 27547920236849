export default function createHash() {

  let $sumbols = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 1, 2, 3, 4, 5, 6, 7, 8, 9, 0];﻿
  let $string = 'Z';
  let $rand
  
  for (let $i = 0; $i < 31; $i++) {
    
    $rand = 0 - 0.5 + Math.random() * (($sumbols.length - 1) - 0 + 1)
    $rand = Math.round($rand);
    $string = $string + '' + $sumbols[$rand];

  }

  return $string;

}