import {
  NEW_GAME,
  SET_KEY,
  TIMER_TICK,
  SERVER_REQUEST,
  SERVER_SUCCESS,
  SERVER_FAILTURE,
} from './actionTypes'

export const newGame = () => ({
  type: NEW_GAME,  
})
export const setKey = key => ({
  type: SET_KEY,  
  payload: { key }
})

export const serverRequest = action => ({
  type: SERVER_REQUEST,
  payload: { action },
})

export const serverSuccess = data => ({
  type: SERVER_SUCCESS,
  payload: { data },
})

export const serverFailture = error => ({
  type: SERVER_FAILTURE,
  payload: { error },
})

export const timerTick = () => ({
  type: TIMER_TICK
})