import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

import gameReducer from '../reducers/gameReducer.js';
import timeReducer from '../reducers/timeReducer.js';

import { gameRequestEpic } from '../epics';

const reducer = combineReducers({
  game: gameReducer,
  time: timeReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const epic = combineEpics(
  gameRequestEpic,
);

const epicMiddleware = createEpicMiddleware();

const store = createStore(reducer, composeEnhancers(
  applyMiddleware(epicMiddleware),
));

epicMiddleware.run(epic);

export default store;
