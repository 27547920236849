import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GameSection from '../components/GameSection'
import Greeting from '../components/Greeting'
import { setKey, newGame } from '../actions/actionCreators'
import createHash from '../other/createHash';

export default function GameContainer({match}) {
  const [firstEntry, setFirstEntry] = useState(true)
  const { userKey, endGame } = useSelector((state) => state.game);
  const dispatch = useDispatch();
  const urlKey = match.params ? match.params.key : false

  useEffect(() => {
    
    if (!userKey && urlKey) {

      /*ключа в сторе нет, но есть урл*/
      dispatch(setKey(urlKey))

    } else if (!userKey && !urlKey) {
      
      /*ключа в сторе и урле нет*/
      dispatch(setKey(createHash()))

    }

  }, [dispatch])

  const startGame = () => {
    setFirstEntry(false);
    dispatch(newGame());
  }

  if (firstEntry) {
    return <Greeting start={startGame}/>
  }

  if (!endGame) {
    return <GameSection />
  }

  if (endGame) {
    return <Greeting win start={startGame}/>
  }

}
