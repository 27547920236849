import $ from 'jquery';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { serverRequest } from '../actions/actionCreators';

import GameGrid from './GameGrid'
import GameInfo from './GameInfo'
import GameControl from './GameControl'
import { stepSound } from '../other/sounds'
import { eagleSound } from '../other/sounds'

export default function GameSection() {

  const game = useSelector((state) => state.game);
  const dispatch = useDispatch();
  const gameSection = React.createRef();

  useEffect(() => {
    dispatch(serverRequest('start'));
    gameSection.current.focus();
  }, [dispatch])

  const handleKeyDown = e => {
    stepSound.play();
	if($("div").is(".ico-eagle")) { eagleSound.play();} // В поле зрения орел
    if (e.keyCode === 37) {
      dispatch(serverRequest('goLeft'))
    } else if (e.keyCode === 38) {
      dispatch(serverRequest('goTop'))
    } else if (e.keyCode === 39) {
      dispatch(serverRequest('goRight'))
    } else if (e.keyCode === 40) {
      dispatch(serverRequest('goBottom'))
    }
  }

  if (game.error) return <p>Ошибка сервера</p>
  return (
    <div className="game-section" onKeyDown={handleKeyDown} tabIndex="0" ref={gameSection}>
      <h1 className="game-title">Пятый элемент</h1>
      {game.error && <p className="error-text">Ошибка сервера. Перегрузите страницу.</p>}
      {game.cells && game.position && (
        <>
          <GameGrid {...game}/>
          <div className="game-common">
            <GameInfo />
            <GameControl />
          </div>
        </>
      )}
    </div>
  )
}
