import UIfx from 'uifx'; 

import treasureAudio from '../sounds/treasure.mp3';
import stepAudio from '../sounds/step.mp3';
import winAudio from '../sounds/win.mp3';
import portalAudio from '../sounds/portal_open.mp3';
import teleportAudio from '../sounds/teleport.mp3';
import injureAudio from '../sounds/scream_male.mp3';
import deathAudio from '../sounds/wasted.mp3';
import eagleAudio from '../sounds/eagle1.mp3';
import varanAudio from '../sounds/varan1.mp3';

export const treasureSound = new UIfx(treasureAudio, {
  volume: 0.95,
  throttleMs: 100
});

export const stepSound = new UIfx(stepAudio, {
  volume: 0.95,
  throttleMs: 100
});

export const winSound = new UIfx(winAudio, {
  volume: 0.95,
  throttleMs: 100
});

export const portalSound = new UIfx(portalAudio, {
  volume: 0.95,
  throttleMs: 300
});

export const teleportSound = new UIfx(teleportAudio, {
  volume: 0.95,
  throttleMs: 100
});

export const injureSound = new UIfx(injureAudio, {
  volume: 0.95,
  throttleMs: 100
});

export const deathSound = new UIfx(deathAudio, {
  volume: 0.95,
  throttleMs: 100
});

export const eagleSound = new UIfx(eagleAudio, {
  volume: 0.95,
  throttleMs: 100
});

export const varanSound = new UIfx(varanAudio, {
  volume: 0.95,
  throttleMs: 100
});
