import $ from 'jquery';
import React from 'react';
import { useDispatch } from 'react-redux';

import { serverRequest } from '../actions/actionCreators';
import { stepSound } from '../other/sounds'
import { eagleSound } from '../other/sounds'

export default function GameControl() {

  const dispatch = useDispatch();
  const goTo = side => {
    stepSound.play();
	if($("div").is(".ico-eagle")) { eagleSound.play();} // В поле зрения орел
    dispatch(serverRequest(side))
  }

  return (
    <div className="control-buttons">
      <div className="row">
        <div onClick={() => goTo('goTop')} className="c-button ico-arrow-top"></div>
      </div>
      <div className="row">
        <div onClick={() => goTo('goLeft')} className="c-button ico-arrow-left"></div>
        <div onClick={() => goTo('goBottom')} className="c-button ico-arrow-bottom"></div>
        <div onClick={() => goTo('goRight')} className="c-button ico-arrow-right"></div>
      </div>
    </div>
  )
}
