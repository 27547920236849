import React from 'react';
import { useSelector } from 'react-redux';

export default function GameFloor() {
	const hp = useSelector((state) => state.game.hp);
	let hs = []
        for (let i = 0; i <= 9 ; i++) {
                if (i < hp) {
                        hs.push(<div className="floor heart-full" key={i}/>)
                } else {
                        hs.push(<div className="floor heart-empty" key={i}/>)
                }
        }

  return (
    <div className="game-hp">
        {hs}
    </div>)
}
