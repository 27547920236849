import {
  TIMER_TICK,
} from '../actions/actionTypes';

const initialState = {
  time: 0,
}

export default function timeReducer(state = initialState, action) {
  switch (action.type) {
    case TIMER_TICK: 
    return {
      ...state,
      time: ++state.time
    }
    default:
      return state;
  }
}