import React from 'react';
import { useSelector } from 'react-redux'
import { winSound } from '../other/sounds'

export default function Greeting(props) {

  const win = props.win ? true : false
  const seconds = useSelector((state) => state.time.time)
  win && winSound.play()

  return (
    <div className="greeting-field">
      <h1 className="header">Пятый элемент</h1>
      {win && (
        <div className="win">
          <div className="win-img"/>
          <p className="win-p">Поздравляем! Вы собрали сокровище за {seconds} сек.!</p>
        </div>
      )}
      <div className="greeting-control">
        <span className="btn-start" onClick={() => props.start()}>НАЧАТЬ НОВУЮ ИГРУ</span>
      </div>
    </div>
  )
}
