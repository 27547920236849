import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { treasureSound } from '../other/sounds'

export default function GameTreasure() {
  const foundedTreasures = useSelector((state) => state.game.itemFounded);
  const [count, setCount] = useState(0)

  if (foundedTreasures && foundedTreasures.length > count) {
    setCount(count+1)
    treasureSound.play()
  }

  const treasuresColor = new Map([
    [1, 'ico-tr-red'],
    [2, 'ico-tr-blue'],
    [3, 'ico-tr-dark-blue'],
    [4, 'ico-tr-orange'],
    [5, 'ico-tr-green'],
  ]);

  return (
    <div className="game-treasure">
      {foundedTreasures && foundedTreasures.map(o => (
        <div key={o} className={"treasure-founded "+treasuresColor.get(o)}/>
        ))}
    </div>
  )
}