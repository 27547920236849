import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import {
  map, retry, exhaustMap, catchError,
} from 'rxjs/operators';
import { of } from 'rxjs';
import {
  SERVER_REQUEST,
} from '../actions/actionTypes';
import {
  serverSuccess,
  serverFailture,
} from '../actions/actionCreators';

const actionsMap = new Map([
  ['start', 1],
  ['goLeft', 13],
  ['goBottom', 12],
  ['goRight', 11],
  ['goTop', 10],
]);

export const gameRequestEpic = (action$ , state$) => action$.pipe(
  ofType(SERVER_REQUEST),
  exhaustMap((o) => ajax({
    url: `${process.env.REACT_APP_REQUEST_URL}`,
    method: 'POST',
    body: JSON.stringify({
      action: actionsMap.get(o.payload.action),
      key: state$.value.game.userKey
    })
  }).pipe(
    retry(3),
    map((o) => serverSuccess(o.response)),
    catchError((e) => of(serverFailture(e))),
  )),
);
