import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GameContainer from './containers/GameContainer'
import { YMInitializer } from 'react-yandex-metrika';
import { teleportSound } from './other/sounds'

function App() {

  const classFloor = 'gf-floor-'+useSelector((state) => state.game.floor);
  useEffect(() => {
    teleportSound.play()
  }, [classFloor])

  return (
    <div className={`global-field ${classFloor}`}>
	<a className="href_wast" href="/">
	<div className="game_over"></div>
	</a>
      <Switch>
        <Route path="/" exact component={GameContainer} />
        <Route path="/:key" component={GameContainer} />
      </Switch>
      <YMInitializer accounts={[56351548]} />
    </div>
  );
}

export default App;
