import $ from 'jquery';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { injureSound } from '../other/sounds';
import { deathSound } from '../other/sounds';
import { varanSound } from '../other/sounds';

export default function GameChgHP() {
  const hp = useSelector((state) => state.game.hp);
	const [hp_old, setC] = useState(0);


  if (hp === 0) {
    // сдох
    deathSound.play();
	$(".game-section").empty();
        $(".href_wast").appendTo(".game-section");
        let gw = $(".game-section").innerWidth();
        $(".game_over").width(gw);
        $(".game_over").show(900);
  }
  else if (hp < hp_old) {
    // ранили

    	setC(hp);
	if($("div").is(".ico-varan"))
	{
		// варан чуствует ранение
		varanSound.play();
	}
    	injureSound.play();

	//$(".game-section").empty();
	//$(".downl_img").appendTo(".game-section");
	//let gw = $(".game-section").innerWidth();
	//$(".downl_img").width(gw);
	//$(".downl_img").show(700);


	
	//$(".game-field").addClass("downl_img");


	//$(".game-field").css('position', 'relative').append('<img style="position:absolute; right:0; bottom:0;width:60px; height:60px;" src="http://formula7.ru/wp-content/uploads/2017/02/2-etap.png"; />'); 

	//$(".game-field").append('<img  src="../portal_up.gif">');
	//$(".game-field").show("slow");
  } 
  else if (hp === 10 && hp_old !== 10) {
	setC(10);
	}
  return ("")
}
