import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { timerTick } from '../actions/actionCreators'

export default function GameTimer() {

  const [isActive, setIsActive] = useState(true);

  const seconds = useSelector((state) => state.time.time)
  const dispatch = useDispatch()

/*  function toggle() {
    setIsActive(!isActive);
  }

  function reset() {
    setSeconds(0);
    setIsActive(false);
  }*/

  useEffect(() => {

    let interval = null;
    if (isActive) {

      interval = setInterval(() => {
        dispatch(timerTick())
      }, 1000);
    
    } else if (!isActive && seconds !== 0) {
    
      clearInterval(interval);
    
    }
    
    return () => clearInterval(interval);
  
  }, [dispatch, seconds]);

  return (
    <div className="game-timer">Время игры: {seconds} сек.</div>
  )
}