import React, { useState,useEffect } from 'react';
import { portalSound } from '../other/sounds'

export default function GameGrid(props) {

  const treasuresColor = new Map([
    [1, 'ico-tr-red'],
    [2, 'ico-tr-blue'],
    [3, 'ico-tr-dark-blue'],
    [4, 'ico-tr-orange'],
    [5, 'ico-tr-green'],
  ]);

  const { cells, position, loading, floor } = props
  const [grid, setGrid] = useState([])

  useEffect(() => {
      let newGrid = []
      let coordinate 
      let classList 
      for (let y = 0; y < 10; y++) {
        for (let x = 0; x < 10; x++) {
          coordinate = x + '' + y
          classList = 'cell '
          if (position === coordinate && !loading) {

            classList += 'ico-player'

          } else if (position === coordinate && loading) {

            classList += 'ico-loading'

          } else {
            switch(cells[coordinate]) {
              case 0:
                classList += 'ico-empty'
                break;
              case 1:
                classList += 'ico-rock'
                break;
              case 4:
		classList += 'ico-cactus'
                break;
              case 5:
                classList += 'ico-varan'
                break;
              case 6:
                classList += 'ico-eagle'
                break;
              case 2:
                portalSound.play();
                classList += 'ico-up'
                break;
              case 3:
                portalSound.play();
                classList += 'ico-down'
                break;
              case 101:
                classList += treasuresColor.get(floor)
                break;
              case 102:
                classList += 'ico-box'
                break;
              default:
                classList += 'ico-sand'
                break;
            }
          }
          newGrid.push(<div
            key={ coordinate }
            className={ classList }/>
          )
        }
      }
      setGrid(newGrid)
  }, [cells, loading])

  return (
	<div>
    <div className="game-field">
      { grid }
	</div>
    </div>

  )
}
