import React from 'react';
import { useSelector } from 'react-redux';

export default function GameFloor() {

	const floor = useSelector((state) => state.game.floor);

	let floors = []
	for (let i = 1; i <= 5 ; i++) {
		if (i === floor) {
			floors.push(<div className="floor act" key={i}/>)
		} else {
			floors.push(<div className="floor dis" key={i}/>)
		}
	}

  return (
    <div className="game-floor">
    	{floors}
    </div>
  )
}