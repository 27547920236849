import {
  NEW_GAME,
  SET_KEY,
  SERVER_REQUEST,
  SERVER_SUCCESS,
  SERVER_FAILTURE,
} from '../actions/actionTypes';

const initialState = {
  hp: 10,
  hp_old :10,
  position: "02",
  cells: [],
  endGame: false,
  floor: 1,
  itemFounded: [],
  loading: false,
  error: null,
  userKey: null,
}

export default function gameReducer(state = initialState, action) {
  switch (action.type) {
    case SERVER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case SERVER_FAILTURE:
      const { error } = action.payload
      return {
        ...state,
        loading: false,
        error,
      }
    case SERVER_SUCCESS:
      const { data } = action.payload
      return {
        ...state,
        loading: false,
        error: null,
	hp: data.hp,
        cells: data.field,
        floor: data.floor,
        position: data.player_coords,
        itemFounded: data.flor_item_collected,
        endGame: data.win === 1 ? true : false,
      }
    case NEW_GAME:
      return {
        ...state,
        time: 0,
        endGame: false,
      }
    case SET_KEY: {
      const { key } = action.payload
      return {
        ...state,
        userKey: key,
      }
    }
    default:
      return state;
  }
}
