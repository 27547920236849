import React from 'react';

import GameTimer from './GameTimer'
import GameMessage from './GameMessage'
import GameTreasures from './GameTreasure'
import GameFloor from './GameFloor'
import GameHP from './GameHP'
import GameChgHP from './GameChgHP'

export default function GameInfo() {
  return (
    <div className="game-info">
	<GameHP/>
      <GameMessage/>
      <GameTimer/>
      <GameTreasures/>
      <GameFloor/>
	<GameChgHP/>
	</div>
  )
}
